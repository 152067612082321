<template>
  <div class="exam-record">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="exam-record-cont">
      <div class="search" >
        <div class="search-item">
          <div class="name">
            分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="(item,fIndex) in categoryList" :key="item.id + 'category'" @click="changeSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <el-table :data="recordData" :loading="recordLoading" size="small" border :header-cell-style="headerCellStyle">
        <el-table-column label="ID" prop="id" fixed="left"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_name')" fixed="left" min-width="200" show-overflow-tooltip>
          <template v-slot='{row}'>
            <router-link style="color:#2d8cf0;"
              :to="{path:'/trainingcamp/exam-detail',query:{
              id:row.train.id,
              tab: 'result'}}">
                {{ row.train.name }}
              </router-link>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('trainingcamp_exam_list_generate_type')">
          <template v-slot='{row}'>
            {{ generatetypesObj[row.train.generate_type] }}
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('trainingcamp_exam_list_generate_id')">
          <template v-slot='{row}'>
            {{ row.train.generate_type == 0?row.train.generate_category.name:row.train.generate_type == 1?row.train.generate_map.name:row.train.generate_type == 2?row.train.generate_group.name:'' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_total_count')" prop="train.data_count"></el-table-column>
        <el-table-column label="总分" prop="train.total_score"></el-table-column>
        <el-table-column label="客观题分数" prop="auto_check_score" min-width="100"></el-table-column>
        <el-table-column label="主观题分数" prop="hand_check_score" min-width="100"></el-table-column>
        <el-table-column label="及格分" prop="train.pass_score"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_finish_count')" prop="have_finished"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_right_count')" prop="right_count"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_score')" prop="score"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_use_time')" prop="use_time"></el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_record_status')">
          <template v-slot='{row}'>
            {{ trainUserStatuses[row.status] }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('trainingcamp_myexam_record_finish_time')" min-width="120" show-overflow-tooltip>
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.finish_time * 1000), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('dataset_trainuser_table_op')" width="260" fixed="right">
          <template v-slot='{row}'>
            <el-button size="mini" type="danger" @click="deleteRecord(row)">删除</el-button>
            <el-button size="mini" type="primary" @click="checkResult(row)">{{ $t('trainingcamp_myexam_record_check_result') }}</el-button>
            <el-button size="mini" type="primary" @click="toReport(row)">查看报告</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <Page :total="totalRecord" @on-change="changeRecordPage" @on-page-size-change="changeRecordPageSize" :current="recordPage" show-total show-sizer show-elevator></Page>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import { MessageBox } from 'element-ui';
export default {
  name: "examRecord",
  data(){
    return{
      util,
      recordData:[],
      recordLoading:false,
      totalRecord:0,
      recordPage:1,
      recordPageSize:10,
      categoryList:[],
      curCategoryId:'0',
      headerCellStyle:{
        backgroundColor: "#F8F8F9",
        color: "#666",
        fontWeight: "bold"
      }
    }
  },
  created(){
    this.getRecordList();
  },
  methods:{
    getRecordList(){
      this.recordLoading = true;
      let params = {
        action:'myTrainList',
        page:this.recordPage,
        pageSize:this.recordPageSize,
        type:1,
        exam_type:1,  //1自测模考 2模考
        category_id:this.curCategoryId
      };
      this.api.dataset.trainExecute(params).then((res)=>{

        this.recordLoading = false;
        if(!res.list.length && this.recordPage > 1){
          this.recordPage = this.recordPage - 1;
          this.getRecordList();
          return;
        }
        this.recordData = res.list;
        this.totalRecord = Number(res.count);
        this.trainUserStatuses = res.trainUserStatuses;//trainuser.status
        this.generatetypesObj = res.generate_types;

        this.categoryList =[
          {
            name:'全部',
            id:0
          },
          ...res.category_list
        ]
      }).catch((err)=>{
        this.recordLoading = false;
      })
    },
    changeSearch(data){
      this.curCategoryId = data.id;
      this.getRecordList();
    },
    // 删除记录
    deleteRecord(row){
      MessageBox.confirm('确定删除当前记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.api.dataset.deleteSelfTestingRecords({
          id:row.id
        }).then(()=>{
          this.$message.success("删除成功");
          this.getRecordList();
        });
      });
    },
    changeRecordPage(page){
      this.recordPage = page;
      this.getRecordList();
    },
    changeRecordPageSize(pageSize){
      this.recordPageSize = pageSize;
      this.getRecordList();
    },
    checkResult(data){
      this.$router.push({
        path:'/trainingcamp/exam',
        query:{
          id:data.train_id || data.id,
          isResult:true,
          isSelf:true
        }
      })
    },
    toReport(data){
      this.$router.push({
        path:'/trainingcamp/train-report',
        query:{
          id:data.id
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.exam-record{
  padding: 20px;
  .exam-record-cont{
    padding: 20px;
    background-color: #FFFFFF;

    .page{
      margin-top: 20px;
      text-align: right;
    }
    .search{
      margin-top: 20px;
      margin-bottom: 20px;
      .search-item{
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;

        .name{
          padding: 5px 0;
          width: 75px;
          text-align: right;
        }
        .cont{
          margin-left: 15px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .values{
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            transition: max-height 0.3s linear;
            overflow: hidden;

            >div{
              padding: 5px 10px;
              //margin: 0 10px 10px 5px;
              cursor: pointer;
            }
            >div.active{
              background-color: #2d8cf0;
              color:#FFFFFF;
              border-radius: 4px;
            }
          }
          .more{
            padding: 5px 0;
            width: 80px;
            text-align: right;
            cursor: pointer;
            .more-icon{
              transition: transform 0.3s linear;
            }
          }
        }
      }
    }
  }
}
</style>
